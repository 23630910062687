import React, { useState, useEffect, useRef } from 'react';
import MessageBubble from './MessageBubble';
import './App.css';

function App() {
  const [messages, setMessages] = useState([]);
  const [question, setQuestion] = useState('');
  const [loading, setLoading] = useState(false);
  const [isListening, setIsListening] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL || 'https://chatbot-liart-seven-26.vercel.app/api/ask';

  // -----------------------------
  // 1) Set up SpeechRecognition
  // -----------------------------
  const recognitionRef = useRef(null);

  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      console.warn('SpeechRecognition not supported in this browser.');
      return;
    }

    recognitionRef.current = new SpeechRecognition();
    recognitionRef.current.continuous = true;    // Keep listening until stopped
    recognitionRef.current.interimResults = false; // If true, you get partial (interim) results
    recognitionRef.current.lang = 'en-US';

    recognitionRef.current.onresult = (event) => {
      let transcript = '';
      for (let i = event.resultIndex; i < event.results.length; i++) {
        if (event.results[i].isFinal) {
          transcript += event.results[i][0].transcript;
        }
      }
      if (transcript) {
        console.log('Recognized speech:', transcript);
        setQuestion(transcript);
      }
    };

    recognitionRef.current.onerror = (err) => {
      console.error('Speech recognition error:', err);
      setIsListening(false);
    };
  }, []);

  // Start or stop listening when `isListening` changes
  useEffect(() => {
    if (!recognitionRef.current) return;
    if (isListening) {
      recognitionRef.current.start();
    } else {
      recognitionRef.current.stop();
    }
  }, [isListening]);

  // -----------------------------
  // 2) Text-to-Speech (TTS)
  // -----------------------------
  const speakText = (text) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      // Optionally configure voice, pitch, rate, etc.
      // utterance.voice = ...
      // utterance.pitch = 1;
      // utterance.rate = 1;
      window.speechSynthesis.speak(utterance);
    } else {
      console.warn('Speech Synthesis not supported in this browser.');
    }
  };

  // -----------------------------
  // 3) Handle Sending Question
  // -----------------------------
  const handleSend = async () => {
    const trimmed = question.trim();
    if (!trimmed) return;

    // Add user message
    setMessages(prev => [...prev, { role: 'user', content: trimmed }]);
    setQuestion('');
    setLoading(true);

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ question: trimmed }),
      });

      if (!response.ok) {
        const errData = await response.json();
        throw new Error(errData.error || 'Server error');
      }

      const data = await response.json();
      const answer = data.answer || 'No answer received.';

      // Add bot response & speak it
      setMessages(prev => [...prev, { role: 'bot', content: answer }]);
      speakText(answer);
    } catch (error) {
      setMessages(prev => [...prev, { role: 'bot', content: `Error: ${error.message}` }]);
      speakText(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') handleSend();
  };

  // Toggle listening state
  const toggleListening = () => {
    setIsListening(prev => !prev);
  };

  return (
    <div className="app-container">
      <header className="header">
        <h1>CareConnect - HealthEquity ChatBot</h1>
      </header>

      <div className="chat-area">
        {messages.map((msg, i) => (
          <MessageBubble key={i} role={msg.role} content={msg.content} />
        ))}
        {loading && <MessageBubble role="bot" content="Thinking..." />}
      </div>

      <div className="input-bar">
        <input
          type="text"
          placeholder="Ask a question about healthcare disparities..."
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button onClick={handleSend} disabled={loading}>
          Send
        </button>
        {/* Speech-to-Text Button */}
        <button
          type="button"
          onClick={toggleListening}
          style={{ marginLeft: '10px' }}
        >
          {isListening ? 'Stop Listening' : 'Speak'}
        </button>
      </div>
    </div>
  );
}

export default App;
